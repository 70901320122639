.reviewCell {
    background-color: #ffffff;
    width: 100%;
    height: 100%;
    gap: 20px;

    box-sizing: border-box; 
    border-radius: 30px;
    padding: 30px;

    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: 75px 1fr;
    border: 2px solid rgba(0, 0, 0, 0.575);
}

.publishDate {
    margin: -10px 0px 20px 0px;
    color:rgb(255, 132, 60);
}

.reviewImage {
    width: 100%;
    height: 100%;

    grid-column: 2;
    grid-row: 1/4;
}

.reviewImage > * {
    width: 100%;
    height: 100%;

    object-fit: cover; 
    border-radius: 30px;
}

.text {
    grid-row: 1;
}

.descriptionText {
    overflow: auto;

    grid-row: 2;
}

.buttons {
    display: flex;
    flex-direction: row;
    gap: 10px;

    grid-row: 3;
}

.reviewButton {
    padding: 10px;
    margin-top: 30px;
    border-radius: 5px;

    max-height: 50px;

    border-color: #ffffff00;
    background-color: rgb(36, 36, 36);
    color: #ffffff;
}

.main {
    display: flex;
    flex-direction: column;
    width: 100%;

    box-shadow: 0px 4px 4px 0px #00000040,inset 0 0 20px 2px rgba(0, 0, 0, 0.295);
}

.gallery {
    flex-direction: column;
    box-sizing: border-box;
    filter: drop-shadow(4px 6px 4px rgba(0, 0, 0, 0.185));
}

.reviewContainer {
    display: flex;
    flex-direction: column;
    width: 900px;
}

.reviewReadContainer {
    display: flex;
    flex-direction: column;
    width: 1280px;
}

.reviewContainer > *, .reviewReadContainer > * {
    margin: 10px 0px 10px 0px;
}

textarea {
    padding: 15px;
    resize: none;
    overflow: auto;
}

.scoreArea {
    margin: 0px 0px 20px 0px;
    background-color: #ffffff;
    padding: 20px;
    border: 1px solid rgb(46, 46, 46);
}

.scoreArea > button {
    background-color: #00000000;
    border-color: #00000000;
    max-width: 50px;
}

.imageChoose {
    padding: 20px;
    background-color: #ffffff;
    border: 1px solid rgb(46, 46, 46);

    width: 100%;
    box-sizing: border-box;
}

input::file-selector-button {
    padding: 10px;
    background-color: #e34242;
    border-color: #00000000;
    border-radius: 5px;
    color:#ffffff;
}

input[type="checkbox"] {
    width: 25px;
    height: 25px;
    cursor: pointer;
    margin-right: 10px;
}

label {
    font-size: large;
    display: flex;
    align-items: center;
}

.createButtonContainer {
    display: flex;
    justify-content: center;
    margin: 10px 0px 20px 0px;
}

.createButton {
    width: 300px;
    height: 75px;
    font-size: 16px;
    border-color: #00000000;
}

.reviewBackground {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    box-shadow: 0px 4px 4px 0px #00000040,inset 0 0 0 1000px rgba(0,0,0,.5);
    flex-direction: column;

    padding: 100px 0px 100px 0px;
    box-sizing: border-box;
}

@media all and (max-width: 1350px) {
    .reviewReadContainer {
        width: 80%;
    }
}

@media all and (max-width: 1024px) {
    .reviewContainer {
        width: 80%;
    }

    .text {
        grid-column: 1/3;
    }

    .descriptionText {
        grid-column: 1/3;
    }

    .reviewCell {
        grid-template-rows: 1fr 1fr 3fr;
    }

    .reviewImage {
        grid-column: 1/4;
        grid-row: 3;
    }

    .buttons {
        grid-row: 4;
    }
}

@media all and (max-width: 375px) {
    input::file-selector-button {
        width: 50%;
    }
}