.backgroundImage {
    background-image: url(../../images/routes_background.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    box-shadow: 0px 4px 4px 0px #00000040,inset 0 0 0 1000px rgba(0,0,0,.5);
    flex-direction: column;

    padding: 100px 0px 100px 0px;
    box-sizing: border-box;
}

.text {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    color: #d1bc8d;
    text-align: center;
    max-width: 50%;
    font-size: 20px;
    filter: drop-shadow(4px 6px 4px black);
}

.gallery {
    flex-direction: column;
    box-sizing: border-box;
    filter: drop-shadow(4px 6px 4px rgba(0, 0, 0, 0.185));
}

.overlay {
    flex-direction: column;
}

.h2 {
    font-size: 36px;
}

.main {
    display: flex;
    flex-direction: column;
    width: 100%;

    box-shadow: 0px 4px 4px 0px #00000040,inset 0 0 20px 2px rgba(0, 0, 0, 0.295);
}

.reviewsGrid {
    max-width: 100%;
    max-height: 100%;

    width: auto;

    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 300px;
    gap: 80px;

    padding: 80px;
    margin: 0px 200px 0px 200px;
    box-sizing: border-box; 
}

.pageSelector {
    display: flex;
    flex-direction: row;
    width: auto;

    background-color: #e34242;
    color: #ffffff;
    border-radius: 30px;

    padding: 25px;
    margin: 0px 15% 0px 15%;
    box-sizing: border-box;
}

.pageSelector > button {
    background-color: rgba(255, 255, 255, 0);
    border-color: rgba(0, 0, 0, 0);
    border-radius: 10px;
    padding: 10px;

    font-size: 20px;
    color: #ffffff;
    margin: 0px 15px 0px 15px;
}

.selectedPageButton {
    background-color: rgba(48, 48, 48, 0.123) !important;
    border-color: rgba(0, 0, 0, 0.199) !important;
}

.rowButtons {
    flex-direction: row;
    gap: 50px;
}

@media all and (max-width: 550px) {
    .text {
        font-size: 4vw;
    }

    .h2 {
        font-size: 7vw;
    }
}

@media all and (max-width: 1440px) {
    .reviewsGrid {
        margin: 0px 25px 0px 25px;
    }

    .pageSelector {
        margin: 0px 7% 0px 7%;
    }
}

@media all and (max-width: 1024px) {
    .reviewsGrid {
        gap: 40px;
        margin: 0px 5px 0px 5px;
        grid-template-rows: 600px;
    }

    .pageSelector > button {
        font-size: 12px;
        margin: 0px 10px 0px 10px;
    }
}

@media all and (max-width: 768px) {
    .pageSelector {
        margin: 0px 7% 0px 7%;
    }
    
    .pageSelector > button {
        font-size: 12px;
        margin: 0px 5px 0px 5px;
    }

    .rowButtons {
        flex-direction: column;
        gap: 0px;
    }
}

@media all and (max-width: 650px) {
    .reviewsGrid {
        margin: 0px 5% 0px 5%;
        padding: 80px 5px 80px 5px;
    }

    .pageSelector {
        margin: 0px 5% 0px 5%;
    }
    
    .pageSelector > button {
        font-size: 3vw;
        margin: 0px -5px 0px -5px;
    }
}