.galleryContainer {
    border-radius: 30px;

    max-width: 1200px;
    max-height: 95%;

    margin: 50px;
    padding: 50px;
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 100px 200px;
    background-color: rgba(0, 0, 0, 0);

    box-sizing: border-box;
}

.galleryImage {
    width: 100%; 
    height: 100%;

    grid-column: 1 / 3;
    grid-row: 1 / 3;
}

.galleryImage > img {
    width: 100%;
    height: 100%;

    object-fit: cover; 
    border-radius: 30px;
}

.galleryTitle {
    font-size: large;
    width: 100%; 
    height: 100%;

    grid-column: 3 / 5;
    grid-row: 1;
}

.galleryTitle > * {
    width: 100%; 
    height: 100%;

    overflow: auto;
}

.galleryDescription {
    font-size: medium;
    width: 100%; 
    height: 100%;

    grid-column: 3 / 5;
    grid-row: 2;
}

.galleryDescription > * {
    width: 100%; 
    height: 100%;
    overflow: auto;
}

@media all and (max-width: 830px){
    .galleryContainer {
        grid-template-rows: 200px 100px 100px 150px;
    }

    .galleryImage {
        grid-column: 1 / 5;
        grid-row: 1 / 3;
    }

    .galleryImage > img {
        object-fit: cover; 
    }

    .galleryTitle {
        grid-column: 1 / 5;
        grid-row: 3;

        font-size: small;
    }

    .galleryDescription {
        grid-column: 1 / 5;
        grid-row: 4;
    }
}

@media all and (max-width: 500px){
    .galleryContainer {
        margin: 30px;
        padding: 20px 20px 40px 20px;
        grid-template-rows: 100px 100px 65px 150px;
    }

    .galleryTitle {
        font-size: x-small;
    }

    .galleryDescription {
        font-size: small;
    }
}