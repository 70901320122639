* {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

#root, .fullScreen {
    display: flex;
    width: 100%;
    height: 100%;
    padding: 0px;
    margin: 0px;
    background-color: #d1bc8d;
    box-sizing: border-box;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 12px;
}

html, body {
    padding: 0px;
    margin: 0px;
    background-color: #d1bc8d;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.header {
    background-color: #e34242;
    width: 100%;
    height: 7vh;
    display: flex;
    padding: 10px 50px 10px 50px;
    color: rgb(255, 255, 255);
    align-items: center;
    box-sizing: border-box;
}

.headerLogo {
    display: flex;
    width: 50%;
    height: 100%;
    justify-content: left;
    align-items: center;
    box-sizing: border-box;
}

.headerNavigation {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: right;
    align-items: center;
    box-sizing: border-box;
}

.headerNavigation > * {
    margin: 0px 40px 0px 40px;
    height: 50%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.cell {
    background-color: #e34242;
    padding: 20px 60px 20px 60px;
    border-radius: 40px;
    color: #ffffff;
}

img {
    max-width: 100%;
    max-height: 100%;
}

.grow {
    transition: transform 0.2s ease-in-out;
    cursor: pointer;
}

.grow:hover {
    transform: scale(1.15);
}

.noSelect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.imageButton {
    background-color: #ffffff00;
    border: none;
    width: 50px;
    max-height: 100%;
}

.imageButton img {
    max-width: 200%;
    max-height: 200%;
}

.fakeLink {
    text-decoration: underline;
    color: #e34242;
    cursor: pointer;
}

.mainDisplay {
    display: flex;
    flex-direction: column;
}

.center {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
}

/* width */
::-webkit-scrollbar {
    width: 20px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgb(104, 104, 104);
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgb(255, 255, 255);
    border-radius: 10px;
    border: 2px solid rgba(0, 0, 0, 0.445);
}