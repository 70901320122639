.routeContainer {
    width: 1280px;
    height: 600px;

    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 30px;

    margin: 10px 0px 20px 0px;
}

.radioField {
    max-height: 100px;
    border-radius: 15px;
    overflow: scroll;
}

.radioField > * {
    margin: 5px 0px 5px 0px;
    font-size: 12px;
}

.buttonField {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
}

.buttonField > button {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background-color: #ffffff00;
    border: 2px solid rgba(0, 0, 0, 0.404);
    font-size: 12px;
}

.routeMap {
    grid-column: 2;
}

.routePoints {
    grid-column: 1;

    gap: 20px;
    overflow-y: auto;
    margin-right: -10px;

    border: 2px solid rgba(0, 0, 0, 0.199);
    border-radius: 10px;
    padding: 10px;
}

.routePointsImage {
    display: flex;
    align-items: center;
    justify-content: center;
}

.routePointsImage > img {
    width: 100px;
}

.cell {
    background-color: #ffffff;
    width: 95%;
    height: 575px;

    box-sizing: border-box; 
    border-radius: 30px;
    padding: 15px;

    margin: 10px 0px 10px 0px;
    font-size: 10px;

    border: 2px solid rgba(0, 0, 0, 0.575);
}

.cell > * {
    overflow: auto;
}

.routeDescription {
    max-height: 60px;
}

@media all and (max-width: 1350px) {
    .routeContainer {
        width: 80%;
        grid-template-columns: 1fr 2fr;
    }
}

@media all and (max-width: 1240px) {
    .routeContainer {
        grid-template-columns: 1fr 1.5fr;
    }
}

@media all and (max-width: 1024px) {
    .routeMap {
        grid-column: 1;
        grid-row: 1;
    }

    .routePoints {
        grid-column: 1;
        grid-row: 2;

        height: max-content;
        overflow: unset;
    }

    .routeContainer {
        grid-template-columns: 1fr;
        grid-template-rows: 400px;
        grid-auto-rows: max-content;

        height: max-content;
    }

    .cell {
        width: 99%;
    }
}