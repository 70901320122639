.backgroundImage {
    background-image: url(../../images/main_background.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    box-shadow: 0px 4px 4px 0px #00000040,inset 0 0 0 1000px rgba(0,0,0,.5);
    flex-direction: column;

    padding: 100px 0px 100px 0px;
    box-sizing: border-box;
}

.text {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    color: #d1bc8d;
    text-align: center;
    max-width: 50%;
    font-size: 20px;
    filter: drop-shadow(4px 6px 4px black);
}

.gallery {
    flex-direction: column;
    box-sizing: border-box;
    filter: drop-shadow(4px 6px 4px rgba(0, 0, 0, 0.185));
}

.overlay {
    flex-direction: column;
}

.h2 {
    font-size: 36px;
}

.main {
    display: flex;
    flex-direction: column;
    width: 100%;

    box-shadow: 0px 4px 4px 0px #00000040,inset 0 0 20px 2px rgba(0, 0, 0, 0.295);
}

@media all and (max-width: 550px) {
    .text {
        font-size: 4vw;
    }

    .h2 {
        font-size: 7vw;
    }
}