.map-wrap {
    position: relative;
    width: 100%;
    height: 100%;
}

.map {
    position: absolute;
    width: 100%;
    height: 100%;

    border-radius: 25px;
    border: 2px solid rgba(0, 0, 0, 0.575);
    box-shadow: 6px 6px 10px 0px #00000027,inset 0 0 20px 2px rgba(0, 0, 0, 0.521);
}