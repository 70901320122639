.authBox {
    display: flex;
    flex-direction: column;
    width: 400px;
    height: 400px;
    background-color: #d1bc8d;
    padding: 50px;
    border-radius: 15px;
    align-items: center;
    justify-content: space-evenly;
}

.verificationBox {
    display: flex;
    flex-direction: column;
    width: 400px;
    height: 200px;
    background-color: #d1bc8d;
    padding: 50px;
    border-radius: 15px;
    align-items: center;
    justify-content: space-evenly;
}

.centerDiv {
    display: flex;
    width: 100%;
    height: 100%;
    
    justify-content: center;
    align-items: center;
    z-index: 10;
}

.authBox > h1, .verificationBox > h1 {
    text-align: center;
    color: #000000;
    font-size: 36px;
    margin-bottom: 50px;
    margin-top: -10px;
}

.authBox > *, .verificationBox > * {
    border-radius: 10px;
    border-color: rgba(0, 0, 0, 0.26);
    padding: 10px;
    width: 100%;
    height: 50px;
    box-sizing: border-box;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.authBox > button, .verificationBox > button {
    background-color: #e34242;
    color: #ffffff;
    font-size: 16px;
    cursor: pointer;
}

.authBox > input, .verificationBox > input {
    font-size: 16px;
}

.authBox > p {
    text-align: center;
    margin: 40px 0px -20px 0px;
    font-size: 16px;
}

.errorMessage {
    margin: -35px -15px -15px 0px;
    color: #c73333;
}

#registrationBox, #loginBox {
    display: flex;
}

.panelBG {
    background-color: rgba(0, 0, 0, 0.603);
    width: 100%;
    height: 100%;
}

.backgroundImage {
    background-image: url(../../images/main_background.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    box-shadow: 0px 4px 4px 0px #00000040,inset 0 0 0 1000px rgba(0,0,0,.5);
    flex-direction: column;
    box-sizing: border-box;

    width: 100%;
    height: 86vh;
}

.forgotLink {
    margin: -10px 0px 0px 0px;
    color: #e0462b;
}

@media all and (max-width: 550px) {
    .authBox {
        width: 75vw;
        height: 400px;
        padding: 50px;
    }

    .verificationBox {
        width: 50vw;
        height: 200px;
    }

    .verificationBox > h1, p {
        font-size: 14px;
    }

    .authBox > h1 {
        font-size: 24px;
    }

    .authBox {
        transform: scale(0.75);
   }
}

@media all and (max-width: 320px) {
    .authBox {
        width: 125vw;
        height: 400px;
        padding: 50px;
    }

   .authBox {
        transform: scale(0.75);
   }
   
   .verificationBox {
    width: 75vw;
        height: 200px;
        transform: scale(0.75);
    }
}